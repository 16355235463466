<template>
    <div id="app">
        <router-view></router-view>
        <b-navbar class="bottom-nav is-fixed-bottom nav-footer" >
            <b-navbar-nav class="nav-menu">
                <b-nav-item>
                    <router-link to="/" tag="li" class="text-center" exact>
                        <b-icon icon="house-door" font-scale="1"></b-icon>
                        <span>Home</span>
                    </router-link>
                </b-nav-item>
                <!-- <b-nav-item>
                    <router-link to="/order-list" tag="li" class="text-center" exact>
                        <b-icon icon="cart-check" font-scale="1"></b-icon>
                    </router-link>
                </b-nav-item> -->
                <b-nav-item>
                    <router-link to="/order-list" tag="li" class="text-center" exact>
                        <b-icon icon="card-list" font-scale="1"></b-icon>
                        <span>Order</span>
                    </router-link>
                </b-nav-item>
                <b-nav-item>
                    <router-link to="/contract-list" tag="li" class="text-center" exact>
                        <b-icon icon="bookmark-check" font-scale="1"></b-icon>
                        <span>Contract</span>
                    </router-link>
                </b-nav-item>
                <b-nav-item @click="logout()">
                    <router-link to="#" tag="li" class="text-center" exact>
                        <b-icon icon="power" font-scale="1"></b-icon>
                        <span>Logout</span>
                    </router-link>
                </b-nav-item>
            </b-navbar-nav>
        </b-navbar>
    </div>
</template>
<script>
    import Sidebar from '../components/partials/Sidebar'
    export default {
        name: 'admin',
        components: {
            Sidebar,
        },
        watch: {
            '$route'(to, from) {}
        },
        methods: {
            logout() {
                window.localStorage.setItem('token', null)
                this.$router.replace('/login')
            }
        }
    }
</script>
<style lang="scss">
    @import "https://cdnjs.cloudflare.com/ajax/libs/bulma/0.7.2/css/bulma.min.css";
    @import "../assets/main.css";

    // .nav-item {
    //     width: 50px;
    //     padding: 0px 54px 0px 20px;
    // }

    .navbar {
        z-index: 999 !important;
    }
    .nav-bg {
        background-color: #fff !important;
        height: 65px;
    }
    .nav-footer {
        background-color: #fff !important;
    }
    .content-section {
        margin-top: 3% !important;
        overflow: scroll;
        height: 100vh;
    }
</style>